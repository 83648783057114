import React, { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useI18next } from "gatsby-plugin-react-i18next"
import { graphql, useStaticQuery } from "gatsby"
import ReactMarkdown from "react-markdown"
import "./bio.css"

const Proximos = () => {
  const { language } = useI18next()

  useEffect(() => {
    if (language === "es") {
      setIdiomaTexto("textoespanol")
    } else if (language === "ca") {
      setIdiomaTexto("textocatalan")
    } else if (language === "en") {
      setIdiomaTexto("textoingles")
    } else if (language === "fr") {
      setIdiomaTexto("textofrances")
    }
  }, [language])

  const [idiomaTexto, setIdiomaTexto] = useState("textoespanol")

  const data = useStaticQuery(graphql`
    {
      cine: allStrapiProximosproyecto(filter: { tipoesp: { eq: "Cine" } }) {
        nodes {
          textocatalan {
            data {
              textocatalan
            }
          }
          textoespanol {
            data {
              textoespanol
            }
          }
          textofrances {
            data {
              textofrances
            }
          }
          textoingles {
            data {
              textoingles
            }
          }
        }
      }
      teatro: allStrapiProximosproyecto(filter: { tipoesp: { eq: "Teatro" } }) {
        nodes {
          textocatalan {
            data {
              textocatalan
            }
          }
          textoespanol {
            data {
              textoespanol
            }
          }
          textofrances {
            data {
              textofrances
            }
          }
          textoingles {
            data {
              textoingles
            }
          }
        }
      }
      television: allStrapiProximosproyecto(
        filter: { tipoesp: { eq: "Series/Tv" } }
      ) {
        nodes {
          textocatalan {
            data {
              textocatalan
            }
          }
          textoespanol {
            data {
              textoespanol
            }
          }
          textofrances {
            data {
              textofrances
            }
          }
          textoingles {
            data {
              textoingles
            }
          }
        }
      }
    }
  `)
  const cine = data.cine.nodes[0]
  const teatro = data.teatro.nodes[0]
  const television = data.television.nodes[0]

  const { t } = useTranslation()
  return (
    <section
      className="proximos container max-w-7xl mt-10 sm:mt-16 px-6 sm:px-10"
      id="pr"
    >
      <h2 className="border-t border-b text-center py-4 text-2xl sm:text-4xl uppercase">
        {t("index.proximosproyectos.titulo")}
      </h2>
      <article>
        <div className="flex flex-col md:flex-row items-stretch justify-between p-3 bg-gris bg-opacity-5 md:space-x-10 py-8">
          <div className="flex items-start md:justify-end md:w-1/4 border-b md:border-b-0 md:border-r md:p-5">
            <h3 className="text-2xl sm:text-4xl sm:mb-5 md:text-right">
              {t("index.proximosproyectos.cineTitulo")}
            </h3>
          </div>
          <div className="flex items-center justify-start md:w-3/4 pt-5 md:p-5">
            <ReactMarkdown className="text-lg text-blanco">
              {cine[idiomaTexto].data[idiomaTexto]}
            </ReactMarkdown>
          </div>
        </div>
        <div className="flex flex-col md:flex-row items-stretch justify-between p-3 bg-gris bg-opacity-5 md:space-x-10">
          <div className="flex items-start md:justify-end md:w-1/4 border-b md:border-b-0 md:border-r md:p-5">
            <h3 className="text-2xl sm:text-4xl sm:mb-5 md:text-right">
              {t("index.proximosproyectos.teatroTitulo")}
            </h3>
          </div>
          <div className="flex items-center justify-start md:w-3/4 pt-5 md:p-5">
            <ReactMarkdown className="text-lg text-blanco">
              {teatro[idiomaTexto].data[idiomaTexto]}
            </ReactMarkdown>
          </div>
        </div>
        <div className="flex flex-col md:flex-row items-stretch justify-between p-3 bg-gris bg-opacity-5 md:space-x-10 pb-8">
          <div className="flex items-start justify-start md:justify-end md:w-1/4 border-b md:border-b-0 md:border-r md:p-5">
            <h3 className="text-2xl sm:text-4xl sm:mb-5 md:text-right">
              {t("index.proximosproyectos.tvTitulo")}
            </h3>
          </div>
          <div className="flex items-center justify-start md:w-3/4 pt-5 md:p-5">
            <ReactMarkdown className="text-lg text-blanco">
              {television[idiomaTexto].data[idiomaTexto]}
            </ReactMarkdown>
          </div>
        </div>
      </article>
    </section>
  )
}

export default Proximos
