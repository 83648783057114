import React, { useEffect, useState } from "react"
import { Transition } from "react-transition-group"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const Slider = () => {
  const data = useStaticQuery(graphql`
    {
      allFile(filter: { relativeDirectory: { eq: "slider" } }) {
        nodes {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, quality: 100)
          }
        }
      }
    }
  `)

  const imagenes = data.allFile.nodes

  const [inProp1, setInProp1] = useState(true)
  const [inProp2, setInProp2] = useState(false)
  const [inProp3, setInProp3] = useState(false)

  const duration = 3000

  const inProps = [inProp1, inProp2, inProp3]

  useEffect(() => {
    let timer = setTimeout(() => {
      if (inProp1 === true && inProp2 === false && inProp3 === false) {
        setInProp1(false)
        setInProp2(true)
      } else if (inProp1 === false && inProp2 === true && inProp3 === false) {
        setInProp2(false)
        setInProp3(true)
      } else {
        setInProp3(false)
        setInProp1(true)
      }
    }, 4000)
    return () => clearTimeout(timer)
  }, [inProp1, inProp2, inProp3])

  const defaultStyle = {
    position: `absolute`,
    transition: `opacity ${duration}ms ease-in-out`,
    opacity: 0,
    width: `100%`,
    height: `100%`,
  }

  const transitionStyles = {
    entering: { opacity: 1 },
    entered: { opacity: 1 },
    exiting: { opacity: 0 },
    exited: { opacity: 0 },
  }

  return (
    <section className="relative container max-w-9xl h-96  sm:h-[650px]">
      {imagenes.map((imagen, index) => (
        <Transition in={inProps[index]} timeout={duration} key={index}>
          {state => (
            <div
              style={{
                ...defaultStyle,
                ...transitionStyles[state],
              }}
            >
              <GatsbyImage
                image={getImage(imagen)}
                alt="Pablo Derqui Slider"
                className="w-full h-full"
              />
            </div>
          )}
        </Transition>
      ))}
    </section>
  )
}

export default Slider
