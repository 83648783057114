import React, { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useI18next } from "gatsby-plugin-react-i18next"
import { graphql, useStaticQuery } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Pderquibio from "../../images/pderquibio.pdf"
import ReactMarkdown from "react-markdown"
import "./bio.css"

const Bio = () => {
  const data = useStaticQuery(graphql`
    {
      allStrapiBiografia {
        nodes {
          biocatalan {
            data {
              biocatalan
            }
          }
          bioespanol {
            data {
              bioespanol
            }
          }
          biofrances {
            data {
              biofrances
            }
          }
          bioingles {
            data {
              bioingles
            }
          }
        }
      }
    }
  `)

  const { language } = useI18next()

  const [biografia, setIBiografia] = useState("")

  useEffect(() => {
    if (language === "es") {
      setIBiografia(data.allStrapiBiografia.nodes[0].bioespanol.data.bioespanol)
    } else if (language === "ca") {
      setIBiografia(data.allStrapiBiografia.nodes[0].biocatalan.data.biocatalan)
    } else if (language === "en") {
      setIBiografia(data.allStrapiBiografia.nodes[0].bioingles.data.bioingles)
    } else if (language === "fr") {
      setIBiografia(data.allStrapiBiografia.nodes[0].biofrances.data.biofrances)
    }
  }, [language, data.allStrapiBiografia.nodes])

  const { t } = useTranslation()
  return (
    <section
      className="biografia container max-w-7xl mt-10 sm:mt-16 px-6 sm:px-10"
      id="bio"
    >
      <h2 className="border-t border-b text-center py-4 text-2xl sm:text-4xl uppercase">
        {t("index.biografia.titulo")}
      </h2>
      <StaticImage
        src="../../images/fotobiografia.jpg"
        className="w-full h-28 sm:h-48"
        alt="Pablo Derqui"
      />
      <ReactMarkdown className="text-lg text-blanco mt-10">
        {biografia}
      </ReactMarkdown>
      <a
        className="inline-block px-8 py-2 rounded-xl bg-negro border border-gris text-gris hover:scale-105 transition duration-100 mt-5"
        href={Pderquibio}
        target="_blank"
        rel="noopener noreferrer"
      >
        PDF Bio
      </a>
    </section>
  )
}

export default Bio
