import React from "react"
import { useTranslation } from "react-i18next"

const Presentacion = () => {
  const { t } = useTranslation()
  return (
    <section className="mt-10 container max-w-7xl px-7 lg:px-20">
      <h1 className="text-3xl sm:text-4xl lg:text-5xl text-center">
        {t("index.titulo")}
      </h1>
      <p className="text-lg sm:text-2xl text-center font-normal mt-5">
        {t("index.descripcion")}
      </p>
    </section>
  )
}

export default Presentacion
