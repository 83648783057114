import React from "react"
import { useTranslation } from "react-i18next"
import { Link } from "gatsby"

const Boton = ({ url }) => {
  const { t } = useTranslation()

  return (
    <Link
      to={url}
      className="px-8 py-2 rounded-xl bg-negro border border-gris text-gris hover:scale-105 transition duration-100 mt-5"
    >
      {t("boton")}
    </Link>
  )
}

export default Boton
