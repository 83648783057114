import React, { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import { graphql } from "gatsby"
import { useI18next } from "gatsby-plugin-react-i18next"
import Layout from "../components/Layout"
import Slider from "../components/index/Slider"
import Presentacion from "../components/index/Presentacion"
import UltimosTrabajos from "../components/index/UltimosTrabajos"
import Bio from "../components/index/Bio"
import Proximos from "../components/index/Proximos"
import Flecha from "../assets/flecha.svg"

const IndexPage = () => {
  const { t } = useTranslation()
  const { language } = useI18next()
  const [flecha, setFlecha] = useState("opacity-0 invisible")

  const handleScroll = () => {
    const position = window.pageYOffset
    if (position < 600) {
      setFlecha("opacity-0 invisible")
    } else {
      setFlecha("visible opacity-100")
    }
  }

  useEffect(() => {
    window.addEventListener("scroll", handleScroll)
  }, [])

  const schema = {
    "@context": t("SEO.index.schema.context"),
    "@type": t("SEO.index.schema.type"),
    name: t("SEO.index.schema.name"),
    description: t("SEO.index.schema.description"),
    url: t("SEO.index.schema.url"),
    image: t("SEO.index.schema.image"),
    sameAs: t("SEO.index.schema.sameAs"),
    jobTitle: t("SEO.index.schema.jobTitle"),
  }

  const datos = {
    title: t("SEO.index.datos.title"),
    description: t("SEO.index.datos.description"),
    lang: language,
  }

  return (
    <Layout schema={schema} datos={datos}>
      <AnchorLink
        to={t(`rutas.home`)}
        className={`${flecha} fixed bottom-14 right-8 md:bottom-28 md:right-10 w-12 h-12 rounded-full border-blanco border-2 bg-azul flex items-center justify-center transition-all duration-500 z-40 hover:-translate-y-2`}
      >
        <Flecha className="w-1/2" />
      </AnchorLink>
      <Slider />
      <Presentacion />
      <UltimosTrabajos />
      <Bio />
      <Proximos />
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
