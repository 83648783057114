import React, { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useI18next } from "gatsby-plugin-react-i18next"
import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Boton from "../Boton"
import Cine from "../../assets/cine.svg"
import Teatro from "../../assets/teatro.svg"
import Tv from "../../assets/tv.svg"

const UltimosTrabajos = () => {
  const { language } = useI18next()

  const [idiomaTitulo, setIdiomaTitulo] = useState("")

  useEffect(() => {
    if (language === "es") {
      setIdiomaTitulo("tituloespanol")
    } else if (language === "ca") {
      setIdiomaTitulo("titulocatalan")
    } else if (language === "en") {
      setIdiomaTitulo("tituloingles")
    } else if (language === "fr") {
      setIdiomaTitulo("titulofrances")
    }
  }, [language])

  const data = useStaticQuery(graphql`
    {
      allStrapiPostcine(sort: { order: DESC, fields: fecha }, limit: 3) {
        nodes {
          fecha
          director
          titulocatalan
          tituloespanol
          titulofrances
          tituloingles
          imagen {
            localFile {
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED)
              }
            }
          }
        }
      }
      allStrapiPostteatro(sort: { order: DESC, fields: fecha }, limit: 3) {
        nodes {
          fecha
          director
          titulocatalan
          tituloespanol
          titulofrances
          tituloingles
          imagen {
            localFile {
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED)
              }
            }
          }
        }
      }
      allStrapiPosttv(sort: { order: DESC, fields: fecha }, limit: 3) {
        nodes {
          fecha
          director
          titulocatalan
          tituloespanol
          titulofrances
          tituloingles
          imagen {
            localFile {
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED)
              }
            }
          }
        }
      }
    }
  `)

  const cine = data.allStrapiPostcine.nodes
  const teatro = data.allStrapiPostteatro.nodes
  const television = data.allStrapiPosttv.nodes

  const { t } = useTranslation()
  return (
    <section className="container max-w-7xl mt-10 sm:mt-16  sm:px-10">
      <h2 className="border-t border-b text-center py-4 text-2xl sm:text-4xl uppercase">
        {t("index.ultimosTrabajos.titulo")}
      </h2>
      <article className="grid  md:grid-cols-2 lg:grid-cols-3 gap-x-5 p-5">
        <div className="flex flex-col items-center justify-start p-3 bg-gris bg-opacity-5">
          <Cine className="w-full h-20" />
          <h3 className="text-2xl mb-5">
            {t("index.ultimosTrabajos.cineTitulo")}
          </h3>
          {cine.map((obra, index) => {
            return (
              <div key={index} className="grid grid-cols-3 p-2 border-t">
                <GatsbyImage
                  image={getImage(obra.imagen.localFile)}
                  className="w-full h-full "
                  alt="cover"
                />

                <div className="col-span-2 p-3">
                  <h3 className="text-2xl text-blanco">{obra[idiomaTitulo]}</h3>
                  <h4 className="font-sans">{obra.director}</h4>
                  <h4 className="font-sans">{obra.fecha}</h4>
                </div>
              </div>
            )
          })}
          <Boton url={t("rutas.cine")} />
        </div>
        <div className="flex flex-col items-center justify-start p-3 bg-gris bg-opacity-5 mt-5 md:mt-0">
          <Teatro className="w-full h-20" />
          <h3 className="text-2xl mb-5">
            {t("index.ultimosTrabajos.teatroTitulo")}
          </h3>
          {teatro.map((obra, index) => (
            <div key={index} className="grid grid-cols-3 p-2 border-t">
              <GatsbyImage
                image={getImage(obra.imagen.localFile)}
                className="w-full h-full "
                alt="cover"
              />

              <div className="col-span-2 p-3">
                <h3 className="text-2xl text-blanco"> {obra[idiomaTitulo]}</h3>
                <h4 className="font-sans">{obra.director}</h4>
                <h4 className="font-sans">{obra.fecha}</h4>
              </div>
            </div>
          ))}
          <Boton url={t("rutas.teatro")} />
        </div>
        <div className="flex flex-col items-center justify-start p-3 bg-gris bg-opacity-5 mt-5 lg:mt-0">
          <Tv className="w-full h-20" />
          <h3 className="text-2xl mb-5">
            {t("index.ultimosTrabajos.tvTitulo")}
          </h3>
          {television.map((obra, index) => (
            <div key={index} className="grid grid-cols-3 p-2 border-t">
              <GatsbyImage
                image={getImage(obra.imagen.localFile)}
                className="w-full h-full "
                alt="cover"
              />

              <div className="col-span-2 p-3">
                <h3 className="text-2xl text-blanco">{obra[idiomaTitulo]}</h3>
                <h4 className="font-sans">{obra.director}</h4>
                <h4 className="font-sans">{obra.fecha}</h4>
              </div>
            </div>
          ))}
          <Boton url={t("rutas.television")} />
        </div>
      </article>
    </section>
  )
}

export default UltimosTrabajos
